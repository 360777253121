import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials"
import logoElec from "../images/fortis-elec.png"
import logoRec from "../images/fortis-reticulation.png"
import logoHandy from "../images/fortis-handyman.png"
import logoplumbing from "../images/fortis-plumbing.png"
import logoAir from "../images/fortis-aircon.png"

export default function Home({ data }) {
  let props = data.allWpPage.edges[0].node.homepage_content
  let heading = props.landingTitle
  let subHeading = props.landingSubheading
  let text = props.landingText
  let btn1_text = props.button1.buttonText
  let btn1_link = props.button1.buttonLink
  let btn2_text = props.button2.buttonText
  let btn2_link = props.button2.buttonLink
  let about_text = props.about
  let about_btn_text = props.aboutButton.buttonText
  let about_btn_link = props.aboutButton.buttonLink
  let about_image = props.aboutImage.sourceUrl

  return (
    <Layout>
      <SEO title="Home" />
      {/* intro */}
      <section className="intro">
        <div id="overlay"></div>
        <div className="container">
          <div className="intro-col">
            <h1>{heading}</h1>
            <span>{subHeading}</span>
            <p>{text}</p>
            <div className="row groups">
              <div className="col-2">
                <Link to="/services/#/">
                  <img src={logoAir} alt="Fortis Group" />
                  <p>Fortis Aircon</p>
                </Link>
              </div>
              <div className="col-2">
                <Link to="/services/fortis-reticulation/">
                  <img src={logoRec} alt="Fortis Group" />
                  <p>Fortis Reticulation</p>
                </Link>
              </div>
              <div className="col-2">
                <Link to="/services/fortis-electrical/">
                  <img src={logoElec} alt="Fortis Group" />
                  <p>Fortis Electrical</p>
                </Link>
              </div>
              <div className="col-2">
                <Link to="/services/fortis-realestate/">
                  <img src={logoHandy} alt="Fortis Group" />
                  <p>Real Estate </p>
                </Link>
              </div>
              <div className="col-2">
                <Link to="/services/fortis-plumbing/">
                  <img src={logoplumbing} alt="Fortis Group" />
                  <p>Fortis plumbing</p>
                </Link>
              </div>
            </div>
            <div className="btn-group">
              <Link to={btn1_link} className="btn">
                {btn1_text}
              </Link>
              <Link to={btn2_link} className="btn btn-secondary">
                {btn2_text}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Services */}
      <section className="testimonials">
        <div className="container">
          <h1>Client Testimonials</h1>
          <Testimonials></Testimonials>
        </div>
      </section>
      {/* About */}
      <section className=" about">
        <div className="container">
          <div className="row">
            <div className="col-7">
              <h1>About Us</h1>

              <div dangerouslySetInnerHTML={{ __html: about_text }} />
              <Link
                to={about_btn_link}
                className="btn btn-secondary noLeftMargin"
              >
                {about_btn_text}
              </Link>
            </div>
            <div className="col-5">
              <img
                className="about__image"
                src={about_image}
                alt="Fortis Group"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="23.25 11.3 53.57 59.24"
              >
                <path
                  transform="translate(50 50)"
                  width="100%"
                  height="100%"
                  d="M17.2,-20.3C22.4,-12.1,26.6,-6.1,26.8,0.3C27.1,6.6,23.4,13.2,18.3,16C13.2,18.7,6.6,17.7,-0.8,18.6C-8.2,19.4,-16.5,22.1,-21.3,19.3C-26.1,16.5,-27.5,8.2,-26.4,1.2C-25.2,-5.9,-21.4,-11.8,-16.6,-20C-11.8,-28.1,-5.9,-38.6,0.1,-38.7C6.1,-38.8,12.1,-28.5,17.2,-20.3Z"
                  fill="#F2BB2F"
                  opacity=".8"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxODk=" } }) {
      edges {
        node {
          homepage_content {
            fieldGroupName
            landingSubheading
            landingText
            landingTitle
            heroImage {
              sourceUrl
            }
            landinglogos {
              sourceUrl
            }
            button1 {
              buttonLink
              buttonText
            }
            button2 {
              buttonLink
              buttonText
            }
            about
            aboutButton {
              buttonLink
              buttonText
            }
            aboutImage {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
//highlight-end
